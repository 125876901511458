<template>
  <router-view/>
</template>

<style>
*{
  scroll-behavior: smooth;
  font-family: 'Hedvig Letters Sans', sans-serif;
}
</style>

<!--*{ background-color: var(&#45;&#45;bs-dark); color: var(&#45;&#45;bs-light); }-->
